<nav>
  <mat-toolbar color="primary">
    <button
      (click)="drawer.toggle()"
      mat-icon-button
      aria-label="Example icon-button with menu icon"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <span>OMS MM JJB</span>
    <span class="flex-auto"></span>
    <button
      mat-icon-button
      aria-label="Example icon-button with share icon"
      [matMenuTriggerFor]="menu"
    >
      <mat-icon>settings</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <!-- <button mat-menu-item (click)="goToChangePasswordPage()">
        <mat-icon>lock</mat-icon>
        <span>Change Password</span>
      </button> -->
      <button mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span>Log Out</span>
      </button>
    </mat-menu>
  </mat-toolbar>
</nav>

<mat-drawer-container class="drawer-container">
  <mat-drawer #drawer class="drawer">
    <mat-nav-list>
      <mat-list *ngFor="let link of links">
        <mat-list-item [routerLink]="link.route" style="cursor: pointer">{{
          link.label
        }}</mat-list-item>
      </mat-list>
    </mat-nav-list>
  </mat-drawer>
  <mat-drawer-content class="mx-auto container py-4">
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
