import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { getDecodedToken } from '../shared/functions';
import { LINKS } from '../shared/variables';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent {
  links: any = [];

  constructor(private router: Router) {
    let user: any = getDecodedToken();

    this.links = LINKS.filter((obj) => obj.accessedBy.includes(user.role_code));
  }

  logout(): void {
    localStorage.removeItem('TOKEN');
    this.router.navigate(['login']);
  }

  goToChangePasswordPage(): void {
    this.router.navigate(['setting']);
  }
}
