import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPageComponent } from './main-page/main-page.component';
import { canActivate } from './shared/guard/auth.guard';
import { NotFoundPageComponent } from './public/not-found-page/not-found-page.component';

const routes: Routes = [
  {
    path: '',
    title: 'OMS MM JJB',
    children: [
      {
        path: '',
        component: MainPageComponent,
        canActivate: [canActivate],
        loadChildren: () =>
          import('./main-page/main-page.module').then((m) => m.MainPageModule),
      },
      {
        path: '',
        loadChildren: () =>
          import('./public/public.module').then((m) => m.PublicModule),
      },
    ],
  },
  { path: '404', component: NotFoundPageComponent },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
