import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { SnackbarConfig } from './enums';
import { LINKS } from './variables';

export function isValueObject(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (
      control.value !== null &&
      control.value !== undefined &&
      typeof control.value !== 'string'
    ) {
      return null;
    }
    return { message: 'Please select the data' };
  };
}

export function checkErrorCode(error: any, router: Router) {
  if (
    error.error.error_schema.error_code === 'TOKEN_EXPIRED' ||
    error.error.error_schema.error_code === 'TOKEN_NOT_FOUND'
  ) {
    router.navigate(['login']);
    return;
  }
}

export function getDecodedToken(): any {
  const token = localStorage.getItem('TOKEN') ?? null;

  if (token) {
    const decodedToken: any = jwtDecode(token);
    return decodedToken;
  }

  return null;
}

export function showSnackbar(snackbar: MatSnackBar, message: string): void {
  snackbar.open(message, SnackbarConfig.BUTTON, {
    horizontalPosition: SnackbarConfig.HORIZONTAL_POSITION,
    verticalPosition: SnackbarConfig.VERTICAL_POSITION,
    duration: 5000,
  });
}

export function canAccessRoute(role_code: string): boolean {
  return LINKS.some((obj: any) => obj.accessedBy.includes(role_code));
}
