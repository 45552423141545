export const LINKS = [
  {
    label: 'Schedule',
    isActive: false,
    route: 'schedule/list',
    accessedBy: ['ADMIN', 'PIC_GL', 'CREW_CHIEF', 'VOLUNTEER'],
  },
  {
    label: 'Evaluation',
    isActive: false,
    route: 'evaluation',
    accessedBy: ['ADMIN', 'PIC_GL', 'CREW_CHIEF'],
  },
  {
    label: 'Volunteer',
    isActive: false,
    route: 'volunteer',
    accessedBy: ['ADMIN', 'PIC_GL'],
  },
  {
    label: 'Church',
    isActive: false,
    route: 'church',
    accessedBy: ['ADMIN', 'PIC_GL'],
  },
  {
    label: 'About',
    isActive: false,
    route: 'about',
    accessedBy: ['ADMIN', 'PIC_GL'],
  },
];

export const LIST_SCHEDULE_VOLUNTEER_DATASOURCE = [
  'schedule_datetime',
  'church_name',
  'service_name',
  'jobdesc_name',
  'actions',
];

export const LIST_SCHEDULE_DATASOURCE = [
  'schedule_datetime',
  'church_name',
  'service_name',
  'evaluated_by',
  'actions',
];
