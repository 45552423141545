import { Component } from '@angular/core';

@Component({
  selector: 'app-evaluation-helper-dialog',
  templateUrl: './evaluation-helper-dialog.component.html',
  styleUrls: ['./evaluation-helper-dialog.component.scss']
})
export class EvaluationHelperDialogComponent {

}
