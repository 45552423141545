import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private httpClient: HttpClient) {}

  getAuthorizationToken(): string {
    return localStorage.getItem('TOKEN') ?? '';
  }

  setAuthorizationToken(token: string): void {
    localStorage.setItem('TOKEN', token);
  }

  login(bodyRequest: any) {
    return this.httpClient.post<any>(
      `${environment.baseUrl}/auth/login`,
      bodyRequest
    );
  }

  goToLoginGMS(): void {
    window.location.href = `${environment.tokenHost}/oauth/authorize?response_type=code&client_id=${environment.clientId}&redirect_uri=${environment.callbackUrl}&scope=profile,ministry`;
  }
}
