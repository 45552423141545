export enum MessageSnackbar {
  'SUCCESS_ADD' = 'Success add new data!',
  'SUCCESS_EDIT' = 'Success edit data!',
  'SUCCESS_DELETE' = 'Success delete data!',
  'FAILED' = 'Something went wrong!',
}

export enum SnackbarConfig {
  'BUTTON' = 'Close',
  'HORIZONTAL_POSITION' = 'end',
  'VERTICAL_POSITION' = 'bottom',
}

// Dialog
export enum DialogType {
  'DELETE' = 'delete',
  'ADD' = 'add',
  'EDIT' = 'edit',
}

export enum Role {
  ADMIN = 'ADMIN',
  PIC_GL = 'PIC_GL',
  CC = 'CREW_CHIEF',
  VOLUNTEER = 'VOLUNTEER',
}
