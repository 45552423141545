<h2 mat-dialog-title>Confirmation</h2>
<mat-dialog-content [ngSwitch]="data.confirmation_type">
  <p *ngSwitchCase="dialogTypeEnum.ADD">Are you sure want to add this data?</p>
  <p *ngSwitchCase="dialogTypeEnum.EDIT">
    Are you sure want to edit this data?
  </p>
  <p *ngSwitchCase="dialogTypeEnum.DELETE">
    Are you sure want to delete this data?
  </p>
  <p *ngSwitchDefault>Are you sure?</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button color="primary" (click)="onSubmitClick()">Submit</button>
</mat-dialog-actions>
